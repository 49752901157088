@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700");
@font-face { 
    font-family: 'montserrat';
    src: url("/fonts/montserrat-light-webfont.eot");
    src: url("/fonts/montserrat-light-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/montserrat-light-webfont.woff2") format("woff2"), url("/fonts/montserrat-light-webfont.woff") format("woff"), url("/fonts/montserrat-light-webfont.ttf") format("truetype"), url("/fonts/montserrat-light-webfont.svg#montserratlight") format("svg");
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: 'montserrat';
    src: url("/fonts/montserrat-regular-webfont.eot");
    src: url("/fonts/montserrat-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/montserrat-regular-webfont.woff2") format("woff2"), url("/fonts/montserrat-regular-webfont.woff") format("woff"), url("/fonts/montserrat-regular-webfont.ttf") format("truetype"), url("/fonts/montserrat-regular-webfont.svg#montserratregular") format("svg");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'montserrat';
    src: url("/fonts/montserrat-bold-webfont.eot");
    src: url("/fonts/montserrat-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/montserrat-bold-webfont.woff2") format("woff2"), url("/fonts/montserrat-bold-webfont.woff") format("woff"), url("/fonts/montserrat-bold-webfont.ttf") format("truetype"), url("/fonts/montserrat-bold-webfont.svg#montserratbold") format("svg");
    font-weight: bold;
    font-style: normal
}

@font-face {
    font-family: 'montserrat';
    src: url("/fonts/montserrat-black-webfont.eot");
    src: url("/fonts/montserrat-black-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/montserrat-black-webfont.woff2") format("woff2"), url("/fonts/montserrat-black-webfont.woff") format("woff"), url("/fonts/montserrat-black-webfont.ttf") format("truetype"), url("/fonts/montserrat-black-webfont.svg#montserratblack") format("svg");
    font-weight: 900;
    font-style: normal
}

@font-face {
    font-family: 'trajanpro';
    src: url("/fonts/TrajanPro-Regular.eot");
    src: url("/fonts/TrajanPro-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/TrajanPro-Regular.woff2") format("woff2"), url("/fonts/TrajanPro-Regular.woff") format("woff"), url("/fonts/TrajanPro-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal
}

blockquote, q {
    quotes: none
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

.table>tbody>tr>td {
    word-wrap: break-word;
}

* {
    box-sizing: border-box
}

body {
    -webkit-font-smoothing: antialiased;
    background: #efefef
	font-family:'montserrat',sans-serif !important;
}

.wrap {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    padding-left: 40px;
    padding-right: 40px
}

@media screen and (max-width: 1030px) {
    .wrap {
        padding-left: 20px;
        padding-right: 20px
    }
}

.clear {
    clear: both
}

.clearfix:after {
    content: "";
    display: block;
    clear: both
}

.bg_teal {
    background: #333F48;
    color: #fff
}

.bg_green {
    background: #4d4d31;
    color: #fff
}

.bg_red {
    background: #994c3a;
    color: #fff
}

.bg_gold {
    background: #988364;
    color: #fff
}

.bg_silver {
    background: #767c83;
    color: #fff
}

.bg_bronze {
    background: #a67d45;
    color: #fff
}

.bg_black {
    background: #000;
    color: #fff
}

.bg_white {
    background: #fff;
    color: #000
}

.bg_grey_dark_1 {
    background: #1f1f1f;
    color: #fff
}

.bg_grey_middle_1 {
    background: #494949;
    color: #fff
}

.bg_grey_light_1 {
    background: #efefef;
    color: #000
}

.button {
    border: 2px solid #fff;
    color: #fff;
    display: inline-block;
    line-height: 24px;
    padding: 16px 32px;
    border-radius: 6px;
    margin-left: 20px;
    font-weight: normal;
    font-size: 16px;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    vertical-align: middle
}

.button>span {
    display: block;
    position: relative;
    z-index: 10;
    color: #fff
}

.button:before {
    display: block;
    content: '';
    width: calc(100% + 30px);
    height: 100%;
    background: #fff;
    position: absolute;
    top: calc(100% + 10px);
    left: -5px;
    transition: all 0.2s
}

.button:hover, a:hover .button {
    color: #1f1f1f
}

.button:hover:before, a:hover .button:before {
    top: 0
}

.button:hover>span, a:hover .button>span {
    color: inherit
}

@media screen and (max-width: 1030px) {
    .button {
        padding: 6px 16px;
        display: block;
        margin: 10px auto 0 !important;
        width: 220px;
        max-width: 100%;
        font-size: 14px
    }
}

body {
    overflow-x: hidden
}

.searchToggle {
    float: right;
    width: 60px;
    height: 60px;
    position: relative;
    display: none;
    z-index: 100;
    transition: all 0.3s
}

.searchToggle svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    left: 20px;
    fill: #333F48
}

@media screen and (max-width: 1030px) {
    .searchToggle {
        display: block
    }
}

@media screen and (max-width: 640px) {
    .searchToggle {
        width: 44px
    }
    .searchToggle svg {
        top: 20px;
        left: 12px
    }
}

body.searchOpen .searchToggle {
    background: #efefef
}

.menuToggle {
    float: right;
    width: 40px;
    height: 40px;
    position: relative;
    display: none;
    z-index: 100
}

.menuToggle span {
    display: block;
    width: 20px;
    height: 2px;
    background: #333F48;
    position: absolute;
    transition: all 0.3s;
    left: 7px
}

div.menuToggle span:nth-of-type(1) {
	top: 10px
}

div.menuToggle span:nth-of-type(2) {
	top: 17px
}

div.menuToggle span:nth-of-type(3) {
	top: 24px
}

@media screen and (max-width: 1030px) {
    .menuToggle {
        display: block
    }
}

body.menuOpen .menuToggle span {
    top: 27px !important
}

body.menuOpen .menuToggle span:nth-of-type(1) {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

body.menuOpen .menuToggle span:nth-of-type(2) {
    opacity: 0
}

body.menuOpen .menuToggle span:nth-of-type(3) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.mainheader {
    padding: 5px 0 0;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
	color:#777;
}

.mainheader a,.mainheader a:hover,.mainheader a:active,.mainheader a:visited{color:inherit;}

.mainheader a.header-btn{
	background:#eee;
	padding:17px;
	margin:5px 0 0 5px;
	border-radius:3px;
	color:#777;
}
.mainheader a.header-btn:hover{
	background:#ddd;color:#333;
}

@media screen and (max-width: 1030px) {
    .mainheader .wrap {
        padding: 0
    }
}

.mainheader>.wrap {
    position: relative;
    background: transparent;
    z-index: 1000;
}

@media screen and (max-width: 1030px) {
	header.mainheader {
        border: none;
		padding:0;
		position:relative;
    }
	header.mainheader #menu-logo{
        float:left;
		width:80%;
		padding:0;
		margin:0;
    }
	header.mainheader a.logo {
        margin: 3px 15px 0;
        height: auto;
		width:auto;
        padding: 0 20px;
        overflow: hidden;
    }
    header.mainheader .logo .logo_full {
        width: 80%;
        height: 40px
    } 
	header.mainheader #top-menu-dt{
        display:none;
    }
	header.mainheader #top-menu-m{
        display:block !important;
		float:right;
		width:20%;
		padding:5px 15px;
    }
    header.mainheader>.wrap {
        padding: 5px 0 0;
        border-top: 0
    }
}

.mainheader a.logo {
    display: block;
    float: left;
    z-index: 100;
    margin: 25px 0 10px;
	width:304px;
    position: relative;
	font-size: 14.5px;
	color:#777;
	font-weight:300;
}
.mainheader a.logo img{
	width: auto; 
	max-width:90%;
	max-height:90px;
	margin-bottom: 6px;
}

.mainheader .logo span {
    display: block
}

.mainheader .logo .logo_full {
    width: 300px;
    height: 50px;
    display: block;
    fill: #333F48
}

@media screen and (max-width: 640px) {
    .mainheader .logo {
        margin: 20px 0;
        height: 16px
    }
    .mainheader .logo .logo_full {
        width: 170px;
        height: 28px
    }
}

.mainmenu {
    position: relative;
    font-size: 18px
}

a {
    text-decoration: none !important
}

.mainmenu a:hover {
    color: #333F48
}

.mainmenu a.button:hover {
    background-color: #fff;
}

.mainmenu>ul>li {
    float: left;
    display: block;
    margin-right: 25px
}

.mainmenu>ul>li>a, .mainmenu>ul>li>span {
    color: #494949;
    padding: 10px 0 20px 0;
    font-weight: normal;
    line-height: 24px;
    display: block;
    cursor: pointer;
    position: relative
}

.mainmenu>ul>li.menu_item_parent>span:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(50% - 10px);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    display: block;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent
}

.mainmenu>ul>li:hover .submenu {
    top: 100%
}

.mainmenu .slug_home {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    overflow: hidden
}

.mainmenu .slug_home>a {
    padding-left: 0;
    padding-right: 0
}

.mainmenu .slug_home>a>span {
    display: none
}

.mainmenu .slug_home .logo_h {
    opacity: 0;
    display: block;
    height: 0;
    width: 0;
    transition: opacity 0.5s, margin 0.5s;
    fill: #333F48
}

.mainmenu .slug_network:hover>span {
    color: #767c83
}

.mainmenu .slug_network:hover>span:after {
    border-bottom-color: #5e6368
}

.mainmenu .slug_network .submenu {
    background: #767c83;
    border-top-color: #5e6368
}

.mainmenu .slug_network .submenu:before, .mainmenu .slug_network .submenu:after {
    border-top-color: #5e6368
}

.mainmenu .slug_yards:hover>span {
    color: #4d4d31
}

.mainmenu .slug_yards:hover>span:after {
    border-bottom-color: #2e2e1d
}

.mainmenu .slug_yards .submenu {
    background: #4d4d31;
    border-top-color: #2e2e1d
}

.mainmenu .slug_yards .submenu:before, .mainmenu .slug_yards .submenu:after {
    border-top-color: #2e2e1d
}

.mainmenu .slug_professionals:hover>span {
    color: #994c3a
}

.mainmenu .slug_professionals:hover>span:after {
    border-bottom-color: #743a2c
}

.mainmenu .slug_professionals .submenu {
    background: #994c3a;
    border-top-color: #743a2c
}

.mainmenu .slug_professionals .submenu:before, .mainmenu .slug_professionals .submenu:after {
    border-top-color: #743a2c
}

.mainmenu .slug_marketplace:hover>span {
    color: #988364
}

.mainmenu .slug_marketplace:hover>span:after {
    border-bottom-color: #796850
}

.mainmenu .slug_marketplace .submenu {
    background: #988364;
    border-top-color: #796850
}

.mainmenu .slug_marketplace .submenu:before, .mainmenu .slug_marketplace .submenu:after {
    border-top-color: #796850
}

.mainmenu .right {
    float: right;
    margin: 0;
    position: relative;
}

.mainmenu .hs-header-dropdown {
    float: right;
    margin: 0;
    position: relative;
    margin-right: -20px
}

.mainmenu .hs-header-dropdown>a {
    padding-right: 20px;
    padding-left: 54px;
    position: relative
}

.mainmenu .hs-header-dropdown.slug_account>a:before {
    content: '';
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    left: 20px;
    top:12px;
    background: url(/img/icon-user.svg) left center no-repeat;
    background-size: 20px auto;
    transition: opacity 0.3s
}

.mainmenu .hs-header-dropdown .submenu_simple {
    position: absolute;
    right: 0;
    width: 100%;
    background: #fff;
    border-top: 5px solid #333F48;
    display: none;
    z-index: 110;
}

.mainmenu .hs-header-dropdown .submenu_simple:before {
    content: '';
    position: absolute;
    bottom: calc(100% + 5px);
    left: calc(50% - 10px);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    display: block;
    width: 0;
    margin-left: 20px;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    border-bottom: 8px solid #333F48
}

.mainmenu .hs-header-dropdown .submenu_simple ul {
    font-size: 16px;
    font-weight: 300;
    list-style-type: none;
    margin-left: 0px;
    padding-left: 0px;
}

.mainmenu .hs-header-dropdown .submenu_simple ul>li>a {
    padding: 15px 20px;
    display: block;
    color: #494949;
    border-top: 1px solid #d0d7dd
}

.mainmenu .hs-header-dropdown .submenu_simple ul>li>a:hover {
    background: #edf0f3
}

.mainmenu .hs-header-dropdown:hover .submenu_simple {
    display: block
}

.mainmenu .submenu {
    position: absolute;
    top: 100%;
    left: 50%;
	display:none;
    width: 100%;
    max-width: 1440px;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    background: #494949;
    border-top: 5px solid #303030;
    z-index: 2000;
}

.mainmenu .menu_item_parent:hover .submenu{
	display:block;
}

.mainmenu .submenu:before, .mainmenu .submenu:after {
    content: '';
    border-top: 5px solid #303030;
    display: block;
    position: absolute;
    top: -5px;
    background: inherit;
    width: 200%;
    height: 100%;
    z-index: 5
}

.mainmenu .submenu:before {
    right: 100%
}

.mainmenu .submenu:after {
    left: 100%
}

.mainmenu .submenu>ul {
    padding-left: 0;
    padding-right: 0;
    position: relative;
    z-index: 10;
    display: block;
    width: 100%;
    margin: 0 -40px 0 0
}

.mainmenu .submenu>ul>li {
    width: 16.66%;
    display: block;
    float: left;
    vertical-align: top;
    position: relative
}

.mainmenu .submenu>ul>li>a, .mainmenu .submenu>ul>li .subsubmenu {
    color: #fff;
    padding: 40px 40px 60px;
    display: block;
    transition: background 0.3s;
    position: relative
}

.mainmenu .submenu>ul>li>a .submenu_title, .mainmenu .submenu>ul>li .subsubmenu .submenu_title {
    display: block;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 120%
}

.mainmenu .submenu>ul>li>a .submenu_description, .mainmenu .submenu>ul>li .subsubmenu .submenu_description {
    display: block;
    font-size: 16px;
    opacity: 0.9;
    font-weight: 300;
    line-height: 140%
}

.mainmenu .submenu>ul>li>a {
    height: 100%
}

.mainmenu .submenu>ul>li>a:hover {
    background: rgba(255, 255, 255, 0.05)
}

.mainmenu .submenu .subsubmenu ul li {
    float: none;
    display: block;
    width: 100%
}

.mainmenu .submenu .subsubmenu ul li a {
    height: auto;
    display: block;
    padding: 5px 0;
    color: #fff;
    font-weight: 300
}

.mainmenu .submenu .subsubmenu ul li a:hover {
    text-decoration: underline
}

.mainmenu .submenu .promo {
    padding: 20px 40px;
    position: relative;
    color: #fff;
    z-index: 20;
    font-weight: 300;
    text-align: left
}

.mainmenu .submenu .promo:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.2);
    width: 200vw;
    height: 100%;
    z-index: 5
}

.mainmenu .submenu .promo .promo_text {
    display: block;
    position: relative;
    z-index: 10
}

@media screen and (max-width: 1440px) {
    .mainmenu {
        font-size: 16px
    }
}

@media screen and (max-width: 1240px) {
    .mainmenu {
        font-size: 14px
    }
    .mainmenu>ul>li {
        margin-right: 20px
    }
}

@media screen and (max-width: 1030px) {
    .mainmenu {
        display: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        background: #fff;
        font-size: 16px;
        transition: all 0.5s;
        z-index: 99999;
        border-top: 1px solid #efefef
    }
    body.menuOpen .mainmenu {
        display: block;
    }
    .mainmenu>ul {
        padding: 0
    }
    .mainmenu>ul>li {
        display: block;
        float: none !important;
        margin: 0 !important
    }
    .mainmenu>ul>li>a, .mainmenu>ul>li>span {
        padding: 15px 48px 15px 20px !important;
        border-bottom: 1px solid #efefef;
		font-family: 'Quattrocento', serif;
		color:#333;
    }
	
	.mainmenu .hs-header-dropdown.slug_account > a::before{display:none;}
    .mainmenu>ul>li.menu_item_parent>a:before, .mainmenu>ul>li.menu_item_parent>span:before {
        content: '';
        display: block;
        border-right: 0;
        border-top: 8px solid #494949;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        position: absolute;
        right: 20px;
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        top: 50%
    }
    .mainmenu>ul>li.menu_item_parent>a:after, .mainmenu>ul>li.menu_item_parent>span:after {
        display: none !important
    }
    .mainmenu>ul>li.slug_home {
        display: block
    }
    .mainmenu>ul>li.slug_home span {
        display: block
    }
    .mainmenu>ul>li.slug_home svg {
        display: none !important
    }
    .mainmenu>ul>li.hs-header-dropdown a:before {
        left: 20px;
        bottom: 20px;
        z-index: 20;
        opacity: 0.5
    }
    .mainmenu>ul>li.slug_network>a:after .submenu>ul>li, .mainmenu>ul>li.slug_network>span:after .submenu>ul>li {
        border-top: 1px solid #5e6368
    }
    .mainmenu>ul>li.slug_yards>a:after .submenu>ul>li, .mainmenu>ul>li.slug_yards>span:after .submenu>ul>li {
        border-top: 1px solid #2e2e1d
    }
    .mainmenu>ul>li.slug_professionals>a:after .submenu>ul>li, .mainmenu>ul>li.slug_professionals>span:after .submenu>ul>li {
        border-top: 1px solid #743a2c
    }
    .mainmenu>ul>li.slug_marketplace.menu_item_open>a:after .submenu>ul>li, .mainmenu>ul>li.slug_marketplace>span:after .submenu>ul>li {
        border-top: 1px solid #796850
    }
    .mainmenu>ul>li.slug_network .submenu>ul>li {
        border-top: 1px solid #5e6368
    }
    .mainmenu>ul>li.slug_yards .submenu>ul>li {
        border-top: 1px solid #2e2e1d
    }
    .mainmenu>ul>li.slug_professionals .submenu>ul>li {
        border-top: 1px solid #743a2c
    }
    .mainmenu>ul>li.slug_marketplace .submenu>ul>li {
        border-top: 1px solid #796850
    }
    .mainmenu>ul>li.menu_item_open .submenu, .mainmenu>ul>li.menu_item_open .submenu_simple {
        display: block !important
    }
    .mainmenu>ul>li.menu_item_open>a:before, .mainmenu>ul>li.menu_item_open>span:before {
        border-top: none !important;
        border-right: 8px solid transparent !important;
        border-left: 8px solid transparent !important;
        border-bottom: 8px solid #494949 !important
    }
    .mainmenu>ul>li.menu_item_open.slug_network>a, .mainmenu>ul>li.menu_item_open.slug_network>span {
        border-bottom-color: #767c83;
        color: #767c83
    }
    .mainmenu>ul>li.menu_item_open.slug_network>a:before, .mainmenu>ul>li.menu_item_open.slug_network>span:before {
        border-bottom: 8px solid #767c83 !important
    }
    .mainmenu>ul>li.menu_item_open.slug_yards>a, .mainmenu>ul>li.menu_item_open.slug_yards>span {
        border-bottom-color: #4d4d31;
        color: #4d4d31
    }
    .mainmenu>ul>li.menu_item_open.slug_yards>a:before, .mainmenu>ul>li.menu_item_open.slug_yards>span:before {
        border-bottom: 8px solid #4d4d31 !important
    }
    .mainmenu>ul>li.menu_item_open.slug_professionals>a, .mainmenu>ul>li.menu_item_open.slug_professionals>span {
        border-bottom-color: #994c3a;
        color: #994c3a
    }
    .mainmenu>ul>li.menu_item_open.slug_professionals>a:before, .mainmenu>ul>li.menu_item_open.slug_professionals>span:before {
        border-bottom: 8px solid #994c3a !important
    }
    .mainmenu>ul>li.menu_item_open.slug_marketplace.menu_item_open>a, .mainmenu>ul>li.menu_item_open.slug_marketplace>span {
        border-bottom-color: #988364;
        color: #988364
    }
    .mainmenu>ul>li.menu_item_open.slug_marketplace.menu_item_open>a:before, .mainmenu>ul>li.menu_item_open.slug_marketplace>span:before {
        border-bottom: 8px solid #988364 !important
    }
    .mainmenu>ul>li a:hover {
        color: #b2bfc8
    }
    .mainmenu .submenu {
        position: relative;
        border-top-width: 0;
        top: 0;
        display: none
    }
    .mainmenu .submenu:before, .mainmenu .submenu:after {
        display: none
    }
    .mainmenu .submenu>ul {
        width: 100%;
        margin: 0
    }
    .mainmenu .submenu>ul>li {
        float: none;
        width: 100%
    }
    .mainmenu .submenu>ul>li>a, .mainmenu .submenu>ul>li .subsubmenu {
        height: auto !important;
        min-height: 0 !important;
        padding: 10px 20px;
        font-size: 14px
    }
    .mainmenu .submenu>ul>li>a .submenu_title, .mainmenu .submenu>ul>li .subsubmenu .submenu_title {
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 2px
    }
    .mainmenu .submenu>ul>li>a .submenu_description, .mainmenu .submenu>ul>li .subsubmenu .submenu_description {
        font-size: 14px
    }
    .mainmenu .submenu .promo {
        padding: 20px;
        font-size: 14px;
        text-align: center;
        overflow: hidden
    }
    .mainmenu .submenu_simple {
        display: none !important
    }
    .mainmenu .hs-header-dropdown .submenu_simple {
        border-top: 0px;
    }
    .mainmenu .hs-header-dropdown .submenu_simple:before {
        display: none;
    }
}

.search {
    float: right;
    margin: 5px 0;
    display: block;
    overflow: hidden;
    width: 45%
}

.search>div {
    border-radius: 2px;
    border: 2px solid #efefef
}

.search input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: none;
    font-size: 14px;
    padding: 10px 20px;
    line-height: 30px;
    outline: none
}

.search input[type="text"], .search input[type="search"] {
    width: calc(100% - 60px)
}

.search button {
    width: 60px;
	height:48px;
    cursor: pointer;
    float: right;
	border:0;
	background:transparent;
}

.search button img {
	width: 15px;
}

@media screen and (max-width: 1030px) {
    .search {
		float:none;
        width: 100%;
        transition: all 0.5s;
        margin: 0;
        padding: 20px;
        border-radius: 0;
        border: none;
        z-index: 30;
        background: #efefef
    }
    .search>div {
        border-color: rgba(0, 0, 0, 0.3)
    }
    body.searchOpen .search {
        display: block !important;
        top: 65px
    }
	div.member-panel{
		margin:0;border-left:0;border-right:0;
	}
}

body.menuOpen {
    overflow: hidden
}

body.scroll_header .logo {
    display: none
}

@media screen and (max-width: 1030px) {
    body.scroll_header .logo {
        display: block
    }
}

body.scroll_header .search {
    display: none
}

body.scroll_header .mainmenu>ul>li>span, body.scroll_header .mainmenu>ul>li>a {
    padding-top: 30px
}

body.scroll_header .slug_home .logo_h {
    opacity: 1;
    height: 24px;
    width: 27px;
    margin-right: 70px
}

@media screen and (max-width: 1240px) {
    body.scroll_header .slug_home .logo_h {
        margin-right: 40px
    }
}

.advert {
    display: table;
    width: 100%;
    text-decoration: none
}

a.advert:hover {
    text-decoration: none;
}

.advert>* {
    display: table-cell;
    table-layout: fixed
}

.advert .advert_image {
    width: 25%;
    text-align: center;
    position: relative;
    vertical-align: middle;
}

.advert .horse-results-photo-holder {
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: white;
    height: 100%;
    max-height: 100%;
}

.advert .advert_image:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 100%;
    width: 40px;
    height: 40px;
    background: inherit;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.advert .advert_image img {
    position: relative;
    display: block;
    z-index: 2;
    margin: auto
}

.advert .advert_text {
    padding: 40px 70px;
    background: #333F48;
    color: #fff
}

.advert .advert_text .advert_text_quote {
    font-weight: 300;
    padding: 0 0 30px 0;
    line-height: 150%;
    font-size: 24px;
    font-style: italic;
    min-height: 105px;
}

.advert .advert_text_profile {
    float: left;
    width: calc(100% - 320px);
    font-size: 18px;
    font-weight: 300;
    position: relative;
    display: table;
    table-layout: fixed
}

.advert .advert_text_profile strong {
    font-weight: bold;
    font-size: 24px;
    display: block;
    padding: 0 0 5px 0
}

.advert .advert_text_profile>* {
    display: table-cell;
    vertical-align: top
}

.advert .advert_text_profile .advert_text_profile_text {
    vertical-align: middle
}

.advert .advert_text_profile .advert_text_profile_image {
    width: 80px
}

.advert .advert_text_profile .advert_text_profile_image>div {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    overflow: hidden
}

.advert .advert_text_profile .advert_text_profile_image img {
    width: 100%;
    display: block
}

.advert .button {
    float: right;
    padding: 12px 32px;
    width: 220px;
    max-width: 100%;
    text-align: center
}

.alert-advert * {
    color: #FFFFFF;
}

.alert-advert select, .alert-advert option {
    color: #000000;
}

@media screen and (max-width: 1440px) {
    .advert .advert_text .advert_text_quote {
        font-size: 20px
    }
    .advert .advert_text_profile {
        font-size: 16px
    }
    .advert .advert_text_profile strong {
        font-size: 20px
    }
}

@media screen and (max-width: 768px) {
    .advert {
        display: block
    }
    .advert>* {
        display: block;
        width: 100% !important
    }
	
    .advert .advert_image:before {
        height: 20px;
        width: 20px;
        top: 100%;
        left: 50%
    }
    .advert .advert_text {
        padding: 30px
    }
    .advert .advert_text .advert_text_quote {
        font-size: 16px;
        padding-bottom: 20px
    }
    .advert .advert_text_profile {
        font-size: 14px;
        width: calc(100% - 250px)
    }
    .advert .advert_text_profile strong {
        font-size: 18px
    }
    .advert .button {
        width: 180px
    }
}

@media screen and (max-width: 640px) {
	.logo{font-size:3vw !important;}
	.mainheader .col-sm-8{display:none;}
    .advert .advert_text_profile {
        width: 100%;
        float: none;
        padding-bottom: 10px
    }
    .advert .button {
        width: 100%
    }
}

.home-place-an-advert {
    display: table;
    text-decoration: none;
    background-color: #988364;
    width: 323px;
    margin-left: 3px;
    height: 76px;
    font-size: 25px;
    padding-top: 0px;
}

.home-place-an-advert .arrow {
    display: block;
    background-color: #a67d45;
    height: 74px;
    margin-top: 0px;
    width: 75px;
    font-size: 28px;
    float: right;
    padding-top: 23px;
}


/*@media screen and (max-width: 768px) {
  .arrow {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .home-place-an-advert {
    width: 160px;
    font-size: 16px;
  }
}*/

.home-place-an-advert span {
    height: 74px;
    display: inline-block;
    vertical-align: middle;
    padding-top: 20px;
    padding-left: 20px;
    color: white;
}

.multiplatform-slogon {
    color: #988364;
    font-size: 20px;
    float: left;
}

.advert-row {
    margin: 10px 0px;
    padding: 0px;
}

.advert-row:nth-child(n+1) {
    margin: 5px 0px;
}


/*Hide the video download button*/

video::-internal-media-controls-download-button {
    display: none;
}

video::-webkit-media-controls-enclosure {
    overflow: hidden;
}

video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
}

@media screen and (max-width: 1030px) {
    body.template-home.menuOpen .mainmenu {
		position:absolute;
		display:block;
        top: 100%;
		padding-top:10px;
    }
	body.template-home.menuOpen .mainmenu .mobile-search{height:125px;}
	.hide-mobile{display:none;}
}


/*Referral member dashboard section*/

div.panel-referral .panel-heading {
    background-color: #994c3a;
}

div.panel-referral span.glyphicon {
    font-size: 1.4em;
}

input#affiliate_id {
    font-size: 0.6em;
    text-align: center;
}

div.panel-heading h2.panel-title {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    color: #FFF;
}


/*Invited landing page*/

div.invited-background {
    position: relative;
    background-color: #fff;
}

div.home-splash-text.invited-landing {
    padding: 10px 25px 30px;
}

div.home-quote-gold.invited-landing h3 {
    font-size: 16px;
}

div.invited-landing h2{
	font-size:22px;
	margin:0 0 10px;
	text-shadow:none;
	color:#333;
}

div.invited-landing h4{
	font-size:18px;
	margin:0 0 10px;
}

div.invited-landing h4,p {
    color:#888;
}
div.invited-background .home-registration{
	min-height:0;
}

/*Competition landing page*/

div.hickstead-background {
    background-image: url(/img/competitions/billy-congo-jump.png);
    min-height: 900px;
    background-repeat: no-repeat;
    background-position: 50% 0;
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
}

div.longines-background {
    background-image: url(/img/competitions/longines.jpg);
    min-height: 300px;
    background-repeat: no-repeat;
    background-position: center center;
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
}

div.home-competition {
    background-color: #333f48;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #FFF;
}

div.row.competition-landing.title {
    background-color: #333f48;
    padding: 40px 20px;
    color: #FFF;
    text-align: center;
}

div.row.competition-landing.subtitle {
    background-color: #988364;
    padding: 4em;
    color: #FFF;
}

div.row.competition-landing.subtitle .hickstead-logo, .longines-logo {
    text-align: right;
}

@media screen and (max-width: 768px) {
    div.row.competition-landing.subtitle .hickstead-logo, .longines-logo {
        text-align: center;
    }
}

div.row.competition-landing.subtitle .hickstead-logo img, .longines-logo img {
    display: inline-block;
}

div.container-fluid.competition .row.signup {
    background-color: #767c83;
}

div.container-fluid.competition .row.signup .row.member-panel {
    background-color: #000;
    color: #FFF;
    border: none;
    padding: 2em 4em;
}

div.container-fluid.competition .row.signup .row.member-panel hr {
    width: 80%;
    height: 2px;
    border: none;
    color: #FFF;
    background-color: #FFF;
}

div.container-fluid.competition .row.signup .row.member-panel p {
    padding-top: 10px
}

div.container-fluid.competition .row.signup .row.member-panel p a {
    color: #FFF;
}

div.container-fluid.competition .row.signup .row.member-panel .signup-button {
    padding-top: 25px;
}

div.container-fluid.competition .row.signup .row.member-panel .signup-button button {
    background-color: #988364;
    border-color: #988364;
}

@media screen and (max-width: 767px) {
    input[name="lastname" ]{
        margin-top: 1em;
    }

    div.container-fluid.competition .row.signup .row.member-panel .signup-button {
        padding-top: 0;
    }
}

div.container-fluid.competition div.disclaimer p {
    margin: 0;
    padding-top: 40px;
}

#user-cookies-policy-accept-container{background:#f4f4f4;border-bottom:1px solid #ddd;}
.promo-strip{background:#8c785c;height:33px;border-bottom:1px solid #423829;font-size:13px;line-height:20px;}
@media screen and (max-width: 768px) {
	#user-cookies-policy-accept-container .btn{display:block;clear:both;margin:10px 0 0;}
	.promo-strip{display:none;}
}
.promo-strip .promo-strip{background:#988364;padding:5px 5px 7px 10px;color:#fff;box-shadow:0 -4px 15px -4px #423829;}
.promo-strip a{color:#ffedd3;}
.promo-strip a:hover{color:#ffe4bf;}
.promo-strip h6{margin:0;color:#ffedd3;padding:5px;}
.promo-strip h6:hover{background:rgba(255,255,255,0.4);color:#002444;}