html {
    overflow-x: hidden;
}

.no-margin {
    margin: 0
}

.no-padding{
    padding: 0
}

body{
    color: #1f1f1f;
    font-size: 14px;
    font-family: 'Work Sans', sans-serif;
	font-weight:300;
	position: relative;
}
@media screen and (max-width: 768px) {
	body{
		font-size: 16px;
	}
}
.label {
    display: inline;
    padding: .2em .6em .2em;
    font-size: 75%;
    font-weight: 700;
    line-height: initial;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}
h1,h2,h3,.menu_item > span,.submenu_title,p.lead{font-family: 'Quattrocento', serif;}
h1 {
    font-size: 22px
}

h2 {
    font-size: 20px
}

h3 {
    font-size: 18px
}

h4 {
    font-size: 16px
}

h5 {
    font-size: 14px
}

p {
    font-size: 14px
}

a {
    color: #1f1f1f
}

a:hover {
    color: #1f1f1f
}
.btn{text-overflow:ellipsis;overflow:hidden;} 
.btn.btn-default:focus{color:#333;}

.form-control{border-radius:2px;}
.form-control:focus {
  border-color: #aaa;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.input-top {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none
}

.input-middle {
    border-radius: 0;
    border-bottom: none
}

.input-bottom {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.header-top {
    background-color: #fff;
    border-bottom: 5px solid #494949;
    padding-bottom: 0;
    padding-top: 30px;
    text-align: center;
    margin-bottom: 0
}

.header-alert {
    z-index: 9999999;
    position: fixed;
    width: 100%;
    opacity: .8;
    background-color: #fafafa;
    border-bottom: 5px solid #1f1f1f;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
    font-size: 18px
}

.header-alert-dismiss {
    margin-right: 40px
}

.header-top-horse {
    border-bottom: 5px solid #988364
}

.header-top-stallion {
    border-bottom: 5px solid #988364
}

.header-top-professional {
    border-bottom: 5px solid #994c3a
}

.header-top-yard {
    border-bottom: 5px solid #333F48
}

.header-top img {
    width: 70%
}

.header-search {
    border-radius: 20px
}

.right-inner-addon {
    position: relative
}

.right-inner-addon input {
    padding-right: 30px
}

.right-inner-addon i {
    position: absolute;
    right: 0;
    padding: 10px 12px;
    pointer-events: none
}

.header-menu {
    text-align: center
}

.header-menu ul {
    list-style: none;
    position: relative;
    display: inline-block;
    padding: 25px 0 0;
    text-transform: uppercase;
    margin: 0;
    bottom: -5px
}

.header-menu li {
    float: left;
    padding: 8px 10px;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 1px;
    margin-right: 10px
}

.header-menu-right li {
    float: right;
    margin-left: 10px;
    text-align: right
}

.header-menu-right li:last-child {
    margin-right: 0
}

.header-menu a {
    color: #1f1f1f;
    padding-bottom: 2px
}

.header-menu a:hover {
    text-decoration: none;
    border-bottom: 2px solid #1f1f1f
}

.header-menu-active {
    background-color: #494949;
    font-weight: 600;
    color: #fff
}

.header-menu-active-horse {
    font-weight: 600;
    color: #fff;
    background-color: #988364
}

.header-menu-active-stallion {
    font-weight: 600;
    color: #fff;
    background-color: #988364
}

.header-menu-active-professional {
    font-weight: 600;
    color: #fff;
    background-color: #994c3a
}

.header-menu-active-yard {
    font-weight: 700;
    color: #fff;
    background-color: #333F48
}

a.header-menu-active-link {
    color: #fff!important
}

a.header-menu-active-link:hover {
    color: #fff;
    border-bottom: 2px #fff solid
}

.sub_menu {
    padding: 8px 0 10px 8px
}

.sub_menu ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.sub_menu li {
    float: left;
    margin-right: 20px;
    font-size: 16px
}

.sub_menu_selected {
    font-weight: 700;
    border: 1px solid #fff;
    padding: 2px 4px 4px;
    border-radius: 2px
}

.sub_menu_horse {
    background-color: #988364;
    color: #fff
}

.sub_menu_stalloin {
    background-color: #988364;
    color: #fff
}

.sub_menu_professional {
    background-color: #994c3a;
    color: #fff
}

.sub_menu_yard {
    background-color: #333F48;
    color: #fff
}

.sub_menu_member {
    background-color: #494949;
    color: #fff
}

.sub_menu li a {
    color: #fff;
    padding-bottom: 2px
}

.sub_menu li a:hover {
    text-decoration: none;
    border-bottom: 2px solid #fff
}

.sub_menu_active {
    border-bottom: 2px solid #fff
}

.search-page {
    background-color: #fff
}

.search-page h2 {
    font-weight: 300;
    margin: 20px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #c8c8c8
}

.search-page h3 {
    font-weight: 300;
    margin: 20px 0 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #c8c8c8
}

.search_results_row_horse {
    font-size: 14px;
    padding: 5px 0;
    border-left: 3px solid #988364;
    border-bottom: 1px solid #c8c8c8;
    margin: 10px 0 5px
}

.search_results_row_pro {
    font-size: 14px;
    padding: 5px 0;
    border-left: 3px solid #994c3a;
    border-bottom: 1px solid #c8c8c8;
    margin: 10px 0 5px
}

.search_results_row_yard, .search_results_row_job, .search_results_row_member {
    font-size: 14px;
    padding: 5px 0;
    border-left: 3px solid #333F48;
    border-bottom: 1px solid #c8c8c8;
    margin: 10px 0 5px
}

.search_results_row_horse p {
    padding: 0;
    margin: 0
}

.search_box_container {
    background-color: #494949;
    padding: 20px 22px 25px
}

.search_box_container label {
    color: #fff;
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 8px
}

.search_box_container .form-control {
    margin-bottom: 10px
}

.search_box_container-button-row {
    margin-top: 20px
}

.search_box_container .btn {
    color: #fff
}

.checkbox .label {
    font-size: 14px
}

.home-background {
    background-image: url(/img/home-background-base.jpg?20171010);
    background-size:cover;
    background-repeat: no-repeat;
	background-position:left bottom;
	padding-top:40px;
    padding-bottom:40px;
	position:relative;
}

#home-background-flex-wrap{
	display: flex;
    flex-wrap: wrap;
}
#home-background-flex-wrap > div.col-sm-4{
	display: flex;
    flex-direction: column;
}
#home-background-flex-wrap::before,#home-background-flex-wrap::after{
	display:none;
	content: normal !important;
}
.home-join-free-banner {
    z-index: 999;
    position: absolute;
    left: 0;
    width: 13%;
    max-width: 165px
}

.home-join-mobile {
    background-color: #346633;
    color: #fff;
    font-size: 16px;
    text-align: right;
    padding: 10px 5px
}

.home-join-mobile a {
    color: #fff
}
.header-tel {
    margin: 20px 0px;
}
@media screen and (min-width: 768px) {
	.split .column{width:50%;}
	.split .column:first-child input{border-right:0;border-top-right-radius:0;border-bottom-right-radius:0;}
	.split .column:last-child input{border-left:1px dotted #bbb;border-top-left-radius:0;border-bottom-left-radius:0;}
}
@media screen and (max-width: 768px) {
	div.split .column{width:auto;float:none !important;display:block;}
	.home-background {
        background-color: #efefef;
		background-position:center center;
    }
    .home-join-free-banner {
        visibility: hidden
    }

    .header-tel {
        margin: 20px;
    }
	.fn-instagram-feed-container{display:none;}
}

@media screen and (max-width: 400px) {
    .btn-group-lg > .btn, .btn-lg {
        font-size: 15px;
    }
}
.home-advertise-banner {
    background-color: #928165;
    padding: 5px 0;
    border-bottom: 2px solid #494949;
    font-size: 20px
}

.home-advertise-banner a {
    color: #fff
}

.home-splash-text {
    position: relative;
    color: #fff;
    padding:0;
}

.home-splash-text h1 {
    font-size:36px;
    margin:0 0 15px;
    text-shadow: 0 0 10px #000;
}

.home-splash-text h2 {
    margin-top: 0;
    font-size:22px;
    font-weight: 100;
	color:#f9f9f9;
	text-shadow:0 0 5px #000;
	margin:0 0 44px;
}

.home-splash-text h2 .highlight-text{
	color:#988364;
	line-height:28px;
	font-weight:600;
}


.invited-landing.home-quote-gold h3{font-weight:300;}
.img-faded{opacity:0.9}

.home-endorsements{
	background: url('/img/black-trans.png') repeat; 
	padding:20px 0 0;
	color:#eee;
	text-shadow:0 0 3px #000;
}
.home-endorsements-label{
	position:absolute;
	top:15px;
	left:15px;
}
div.home-advocate{
	z-index:99;
	min-height:135px;
	padding:15px 25% 0 15px; 
	background-position:right bottom;
	background-size:auto 100%;
	background-repeat:no-repeat;
	font-size:16px;
	color:#f6f6f6;
}
div.home-advocate a{color:#337ab7;}
div.home-advocate em{font-weight:300;}

@media screen and (max-width: 768px) {
	div.home-advocate{height:195px;}
	.home-splash-text h2{margin-bottom:30px;}
	.home-endorsements{padding-top:0;margin-bottom:20px;}
	.carousel-indicators,.home-endorsements-label{display:none;}
	div.home-advocate{padding-bottom:15px;background-size:auto 75%;background-origin:padding-box;}
	.home-registration .btn{display:block;width:100%;}
	.home-background .col-sm-4{width:100%;}
}
@media screen and (max-width: 415px) {
	div.home-advocate{height:270px;}
	div.home-advocate:nth-child(2){background-position:right -110px bottom;}
	div.home-advocate:nth-child(3){background-position:right -70px bottom;}
}

.home-registration{
	box-sizing:border-box;
	height:100%;
	padding:15px 30px;
	min-height: 316px;
	background:transparent url('/img/white-trans.png') repeat;
}
.home-registration hr{margin:15px 0 5px;}
.home-registration .form-group{margin-bottom:5px;}
.home-registration .btn.btn-lg{font-size:15px !important;border-color:#999;} 

.home-advert img{max-width:100%;}
.home-advert .insta-row{display:block;position:relative;background:#f0f0f0;border-bottom:1px solid #444;margin:0 0 5.5px;}
.home-advert .insta-row::after{display:table;content:" ";clear:both;height:0;line-height:0;}
.home-advert .insta-img{float:left;width:25%;}
.home-advert .insta-caption{box-sizing:border-box;float:left;width:75%;padding:7px 10px;}
.home-advert .insta-row > div{position:absolute;bottom:0;right:0;color:#777;font-size:10px;padding:5px;background:#fff;}
.feeds{padding:25px 0 10px;}
.feed .feed-title{border-top:5px solid transparent;margin:0;padding:10px 0 6px;color:#fff;font-size:15px;text-transform:uppercase;}
.social .feed .feed-title{border-color:#767c83;color:#767c83;}
.professional .feed .feed-title{border-color:#994c3a;color:#994c3a;}
.marketplace .feed .feed-title{border-color:#988364;color:#988364;}
.feed .feed-item{display:block;padding:5px;height:90px;overflow:hidden;box-sizing:content-box;margin:0 -5px;}
.feed .feed-item:hover{background:#f6f6f6;cursor:pointer;}
.feed .feed-item:last-child{border-bottom:1px solid #e4e4e4;}
.feed .feed-item .feed-item-image{float:left;height:100%;width:40%;padding:9% 0;background-size:cover;background-position:center center;} 
.feed .feed-item .feed-item-text{float:left;width:60%;padding:0 0 0 10px;}
.feed .feed-item .feed-item-text h6{font-size:13px;text-transform:uppercase;padding:0;margin:-3px 0 2px;max-height:36px;overflow:hidden;line-height:18px;}
.feed .feed-item .feed-item-text p{margin:0;color:#666;line-height:19px;font-weight:300;}
.feed-cta{padding:5px 0 0;}
.feed-cta .column:first-child{padding-right:5px;}
.feed-cta .column:last-child{padding-left:5px;}
.feed-cta .btn-primary{border-color:transparent;}
.social .feed-cta .btn-primary{background:#767c83;;}
.professional .feed-cta .btn-primary{background:#994c3a;}
.marketplace .feed-cta .btn-primary{background:#988364;}
.social .feed-cta .btn-primary:hover{border:1px solid #000;opacity:0.9;}

.placeholder{background:#eee;height:250px;color:#999;padding:15px;}
.placeholder.banner{height:150px;}

.home-search {
    background-repeat: repeat-x;
    background-color: #efefef;
    padding-top: 5px;
    border-top: 4px solid #494949
}

@media screen and (max-width: 768px) {
    .home-search {
        border-top: none
    }
    .member-top-menu p.pull-left {
        position: relative;
        z-index: 91f1f1f;
        top: 17px;
        left: 17px
    }
    .member-top-menu p.pull-left .hide-mobile {
        display: none
    }
	.feed-cta .column{padding:5px 0 !important;}
}

.home-search-wide {
    margin-top: 0!important;
    margin-bottom: 0!important
}

.home-quote-gold {
    background-color: #494949;
    text-align: center;
    color: #fff;
    padding: 40px 20px
}

.home-quote-gold h3 {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    margin: 0 0 10px
}

.home-quote-gold p {
    font-size: 12px;
    color: #fff;
    margin: 0;
    padding: 0
}

.home-quote-white {
    background-color: #fff;
    text-align: center;
    color: #494949;
    padding: 30px 0;
    border-top: 1px solid #494949;
    border-bottom: 1px solid #494949
}

.home-quote-white h3 {
    font-size: 18px;
    font-weight: 400;
    color: #494949;
    margin: 0 0 10px
}

.home-quote-white p {
    font-size: 12px;
    color: #494949;
    margin: 0;
    padding: 0
}

.home-section-white {
    background-color: #fff
}

.home-section {
    color: #494949;
    padding: 60px 0
}

.home-section h3 {
    font-size: 32px;
    font-weight: 200
}

.home-section p {
    font-size: 18px;
    font-weight: 400
}

.home-mobile-icons {
    vertical-align: bottom;
    margin-left: 10px
}

.home-text-box {
    background: #fff;
    padding: 8px
}

.home-text-box img {
    width: 100%
}

.home-text-box ul {
    list-style: none;
    padding: 10px;
    margin: 0
}

.home-text-box li {
    margin: 5px 0
}

.home-text-box-left {
    margin-right: 8px
}

.home-text-box-center {
    margin: 0 4px
}

.home-text-box-right {
    margin-left: 8px
}

.home-text-box-title {
    font-size: 20px;
    padding: 5px 10px
}

.home-text-box-title a {
    color: #fff
}

.home-text-box-title a:hover {
    text-decoration: none;
    border-bottom: 2px solid #fff
}

.home-text-box-base-link {
    font-size: 16px;
    padding: 5px 10px
}

.home-text-box-base-link a:hover {
    text-decoration: none;
    border-bottom: 2px solid #1f1f1f
}

.home-text-box-row-spacing {
    margin-top: 12px
}

.home-text-box-base-link a {
    color: #fff
}

.home-text-box-base-link a:hover {
    text-decoration: none;
    border-bottom: 2px solid #fff
}

.home-front-page-buttons .btn {
    margin: 0 5px 10px
}

.home-show-all {
    padding-top: 10px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 200;
    letter-spacing: 2px
}

.home-show-all a {
    background-color: #646464;
    border-radius: 10px;
    padding: 5px 10px;
    color: #fff
}

.home-show-all a:hover {
    text-decoration: none;
    background-color: #fff;
    color: #646464
}

.page-container {
    margin-top: 40px;
    background-color: #fff;
    padding: 10px 30px
}

.page-container h1 {
    font-size: 32px;
    font-weight: 300
}

.page-container h2 {
    font-size: 18px;
    font-weight: 700
}

.page-container li {
    margin-bottom: 10px
}

.page-table-heading {
    font-size: 18px
}

.page-table-heading-pricing {
    font-size: 15px;
    font-weight: 100!important
}

.page-table-sub-heading {
    font-size: 16px;
    background-color: #f0f0f0!important;
    border-top: 2px solid #c8c8c8!important;
    color: #646464
}

.tabbed-search-container {
    margin: 40px 0
}

.tabbed-search-container .nav-tabs {
    border-bottom: none
}

.tabbed-search-container .nav-tabs li {
    background-color: #494949;
    opacity: .7;
    margin: 0 3px;
    color: #efefef;
    border-bottom: none
}

.tabbed-search-container .nav-tabs>li>a {
    color: #efefef;
    padding: 5px 10px;
    margin: 0
}

.tabbed-search-container .nav-tabs>li>a:hover {
    background-color: #fff;
    color: #494949;
    opacity: 1;
    border-radius: 0;
    border: 1px solid #fff
}

.tabbed-search-container .active {
    background-color: #494949;
    opacity: 1!important;
    color: #fff
}

.tabbed-search-container .nav-tabs>li.active>a,
.tabbed-search-container .nav-tabs>li.active>a:hover,
.tabbed-search-container .nav-tabs>li.active>a:focus {
    color: #646464!important;
    border-radius: 0;
    background: none;
    border: 1px solid #dcdcdc;
    background-color: #dcdcdc;
    margin: 0
}

.tabbed-search-container .tab-pane {
    padding: 15px 10px;
    border-top: none;
    width: 100%;
    background-color: #dcdcdc;
    min-height: 200px
}

.tabbed-search-container label {
    color: #fff;
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 8px
}

.top-border-horse {
    border-top: 3px solid #988364
}

.top-border-stallion {
    border-top: 3px solid #988364
}

.top-border-professional {
    border-top: 3px solid #994c3a
}

.top-border-yard {
    border-top: 3px solid #333F48
}

.bottom-border-horse {
    border-bottom: 3px solid #988364!important
}

.bottom-border-stallion {
    border-bottom: 3px solid #988364!important
}

.bottom-border-professional {
    border-bottom: 3px solid #994c3a!important
}

.bottom-border-yard {
    border-bottom: 3px solid #333F48!important
}

.btn-horse {
    background-color: #988364;
    color: #fff
}

.btn-horse-outline {
    background-color: #fff;
    border: 1px solid #988364;
    color: #988364
}

.btn-horse-outline:hover {
    background-color: #988364;
    color: #fff
}

.btn-stallion {
    background-color: #988364;
    color: #fff
}

.btn-stallion-outline {
    background-color: #fff;
    border: 1px solid #988364;
    color: #988364
}

.btn-stallion-outline:hover {
    background-color: #988364;
    color: #fff
}

.btn-professional {
    background-color: #994c3a;
    color: #fff
}

.btn-professional-outline {
    background-color: #fff;
    border: 1px solid #994c3a;
    color: #994c3a
}

.btn-professional-outline:hover {
    background-color: #994c3a;
    color: #fff
}

.btn-yard {
    background-color: #333F48;
    color: #fff
}

.btn-yard-outline {
    background-color: #fff;
    border: 1px solid #333F48;
    color: #333F48
}

.btn-yard-outline:hover {
    background-color: #333F48;
    color: #fff
}

.btn-join {
    background-color: #988364;
    color: #fff
}

.text-box-horse {
    color: #fff;
    background-color: #988364
}

.text-box-professional {
    color: #fff;
    background-color: #994c3a
}

.text-box-yard {
    color: #fff;
    background-color: #333F48
}

.horse-results {
    background-color: #fff;
    margin: 5px 0 0;
    padding: 15px 20px 20px;
    border: 1px solid #dcdcdc
}

.horse-results h2 {
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: 200
}

.horse-results-row-details {
    padding: 0
}

.horse-results-photo {
    width: 100%
}

.horse-results-photo-holder {
    max-height: 250px;
    width: 100%;
    background-color: #efefef;
    background-position: center center;
    background-size: cover
}

.horse-results-photo-holder-top {
    width: 100%;
    background-color: #efefef;
    background-position:center center;
    background-size:100% auto; 
	background-repeat: no-repeat;
}

.horse-results hr {
    margin: 5px 0
}

.horse-results-row-spacing {
    margin-top: 10px;
    border-top: 1px solid #f0f0f0;
    padding-top: 10px
}

.horse-results-row-spacing-base {
    margin-top: 30px
}

.horse-results-qualification-table {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%
}

.horse-results-qualification-table th,
.horse-results-qualification-table td {
    border: 1px solid #c8c8c8
}

.horse-results-qualification-table th {
    background-color: #dcdcdc;
    color: #969696;
    text-transform: uppercase;
    font-weight: 200;
    padding: 4px;
    letter-spacing: 1px;
    font-size: 12px;
    text-align: center
}

.horse-results-qualification-table td {
    text-align: center;
    padding: 4px
}

.horse-results-detail-table table {
    margin-left: 0;
    margin-right: 0;
    width: 100%
}

.horse-results-detail-table th {
    padding: 8px 8px 8px 4px;
    font-weight: 700;
    text-align: right;
    border-bottom: 1px solid #c8c8c8;
    width: 10%;
    vertical-align: middle;
    font-size: 12px;
    line-height: 13px;
    color: #494949
}

.horse-results-detail-table.profession th {

    width: 12%;
}


.horse-results-detail-table td {
    padding: 8px 0 8px 4px;
    border-bottom: 1px solid #c8c8c8;
    width: 40%;
    vertical-align: top;
    font-size: 14px
}

.horse-results-tree-table {
    text-align: right;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px
}

.horse-results-tree-table td {
    padding: 5px;
    font-size: 13px
}

.horse-results-tree-table-right-col {
    text-align: left;
    color: #969696;
    font-style: italic
}

.horse-results .text-muted {
    color: #aaa
}

.horse-results-media-container {
    margin: 5px 0 0
}

.horse-results-media-icon {
    float: left;
    width: auto;
    margin: 0 10px 0 0;
    padding: 0
}

.horse-results-media-label {
    float: left;
    font-size: 20px;
    padding: 0 10px 0 0
}

.results {
    background-color: #fff;
    margin: 5px 0 0;
    padding: 15px 20px 20px;
    border: 1px solid #dcdcdc
}

.results h2 {
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: 200
}

.results-row-details {
    padding: 0
}

.results-photo {
    width: 100%
}

.results hr {
    margin: 5px 0
}

.results-row-spacing {
    margin-top: 10px;
    border-top: 1px solid #f0f0f0;
    padding-top: 10px
}

.results-row-spacing-base {
    margin-top: 30px
}

.results-qualification-table {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%
}

.results-qualification-table th,
.results-qualification-table td {
    border: 1px solid #c8c8c8
}

.results-qualification-table th {
    background-color: #dcdcdc;
    color: #969696;
    text-transform: uppercase;
    font-weight: 200;
    padding: 4px;
    letter-spacing: 1px;
    font-size: 12px;
    text-align: center
}

.results-qualification-table td {
    text-align: center;
    padding: 4px
}

table.results-detail-table {
    margin-left: 0;
    margin-right: 0;
    width: 100%!important
}

.results-detail-table th {
    padding: 8px 8px 8px 4px;
    font-weight: 700;
    text-align: right;
    border-bottom: 1px solid #c8c8c8;
    width: 10%;
    vertical-align: middle;
    font-size: 12px;
    line-height: 13px;
    color: #494949
}

.results-detail-table td {
    padding: 8px 0 8px 4px;
    border-bottom: 1px solid #c8c8c8;
    width: 40%;
    vertical-align: top;
    font-size: 14px
}

.results-tree-table {
    text-align: right;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px
}

.results-tree-table td {
    padding: 5px;
    font-size: 13px
}

.results-tree-table-right-col {
    text-align: left;
    color: #969696;
    font-style: italic
}

.results .text-muted {
    color: #bbb
}

.results-media-container {
    margin: 5px 0 0
}

.results-media-icon {
    float: left;
    width: auto;
    margin: 0 10px 0 0;
    padding: 0
}

.results-media-label {
    float: left;
    font-size: 20px;
    padding: 0 10px 0 0
}

.results-text-min-height {
    min-height: 120px
}

.results-no-results {
    text-align: center
}

.results-no-results h2 {
    font-size: 32px;
    font-weight: 300
}

.results-no-results hr {
    margin: 10px 0
}

.results-no-results p {
    font-size: 20px;
    font-weight: 300
}

.member-panel {
    margin-top: 45px;
    margin-bottom: 45px;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    padding: 0 20px 20px
}

.member-panel h2 {
    font-size: 20px;
    border-bottom: 1px solid #c8c8c8;
    padding-bottom: 10px;
    margin-bottom: 10px;
    color: #646464
}

.member-panel hr {
    margin-bottom: 10px
}

.member-panel legend {
    font-size: 16px;
    color: #969696
}

.member-facebook-button {
    width: 100%
}

.member-top-menu {
    color: #646464;
    background: #f0f0f0;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: 1px solid #c8c8c8;
    font-weight: 200
}

.member-top-menu ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.member-top-menu p {
    margin-bottom: 0;
    line-height: 30px
}

.member-top-menu li {
    float: left;
    margin: 0 10px 0 0;
    padding: 6px 0 5px 10px;
    border-left: 1px solid #c8c8c8
}

.member-top-menu li:last-child {
    border-right: 1px solid #c8c8c8;
    padding-right: 6px
}

.member-top-menu a {
    padding-bottom: 0;
    color: #646464
}

.member-top-menu a:hover {
    text-decoration: none;
    border-bottom: 2px solid #1f1f1f
}

.member-top-menu-acive a {
    border-bottom: 2px solid #1f1f1f
}

.members-page {
    margin-bottom: 40px
}

.members-page h1 {
    font-weight: 200
}

.members-page h2 {
    font-size: 20px
}

.members-page h3 {
    font-size: 20px;
    font-weight: 200
}

.members-page h4 {
    font-size: 18px;
    font-weight: 200
}

.members-page img {
    width: 100%
}

.members-page-feed {
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 30px;
    box-shadow: 0 0 2px #646464
}

.members-page-feed h1 {
    margin-top: 0
}

.members-page-feed h2 {
    margin-top: 0
}

.members-page-feed h3 {
    margin-top: 0
}

.members-super-large-icon {
    font-size: 70px;
    color: #c8c8c8
}

.members-page-photo-holder {
    background-size: cover
}

.members-header img {
    width: 100%
}

.members-header ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.members-header li {
    float: left;
    padding: 0;
    margin: 0 10px 0 0
}

.member-edit h2 {
    margin-bottom: 20px
}

.member-tab-content {
    background-color: #fff;
    padding: 20px 10px;
    margin-bottom: 20px;
    border: 1px solid #dcdcdc;
    border-top: none
}

.members-page-connections {
    margin-bottom: 20px
}

.members-page-connections img {
    margin-bottom: 10px
}

.member-section {
    padding-bottom: 30px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 40px
}

.member-section h4 {
    margin-top: 20px
}

.member-connection-row {
    margin-top: 15px;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 15px
}

a.member-remove-connection {
    color: #969696;
    font-size: 11px;
    border: 1px solid #969696;
    padding: 2px 4px;
    border-radius: 2px
}

a.member-remove-connection:hover {
    color: #0a0a0a;
    border: 1px solid #0a0a0a;
    text-decoration: none
}

.members-settings-col {
    border-right: 1px solid #c8c8c8
}

.member-no-video {
    border: 1px solid #c8c8c8;
    color: #c8c8c8;
    text-align: center;
    padding: 100px 20px;
    margin-bottom: 10px
}

.members-media-col {
    border-right: 1px solid #c8c8c8
}

.members-update-row {
    padding: 0 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 15px
}

.members-page-table-label {
    font-size: 10px;
    text-transform: uppercase;
    padding: 2px;
    text-align: center;
    color: #fff;
    width: 55px;
    border-radius: .5em
}

.members-page-table-label-sale {
    background-color: #994c3a
}

.members-page-table-label-stud {
    background-color: #767c83
}

.members-page-table-label-hidden {
    background-color: #bbb
}

.members-page-label {
    font-size: 18px;
    text-transform: uppercase;
    padding: 4px;
    text-align: center;
    color: #fff;
    width: 200px;
    border-radius: .5em
}

.members-page-label-sale {
    background-color: #994c3a
}

.members-page-label-stud {
    background-color: #767c83
}

.members-page-label-hidden {
    background-color: #efefef
}

.members-icon-large {
    font-size: 30px
}

.members-panel h2 {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    color: #6A6A6A
}

.members-panel h3 {
    margin: 10px 0 20px
}

.members-panel th {
    font-size: 12px;
    font-weight: 700;
    color: #666;
    padding: 5px;
    border-bottom: 1px solid #D4D4D4
}

.members-panel table {
    width: 100%
}

.members-panel thead {
    border-bottom: none
}

.members-panel td {
    font-size: 14px;
    padding: 7px 5px;
    border-bottom: 1px solid #D4D4D4
}

.members-panel-button {
    text-transform: uppercase;
    margin-left: 10px;
    font-size: 14px;
    color: #1f1f1f;
    letter-spacing: .5px
}

.members-panel-button:hover {
    text-decoration: none;
    border-bottom: 2px solid #1f1f1f
}

.members-hr {
    border-top: 1px solid #efefef
}

.members-sidebar-counts {
    color: #6A6A6A;
    font-size: 24px;
    line-height: 65px;
    font-weight: 200
}

.members-sidebar-counter {
    float: left;
    font-size: 46px;
    text-align: right;
    padding-right: 0;
    font-weight: 300
}

.members-label-col {
    width: 10%
}

.member-connection-image {
    height: 50px!important;
    width: 50px!important;
    background-color: #646464
}

.members-connections-photo-col {
    width: 50px
}

.members-connections-text-col {
    padding-left: 0!important;
    padding-top: 12px!important
}

.members-connections-text-col a:hover {
    text-decoration: none
}

.members-back-bar {
    background-color: #494949;
    color: #fff;
    font-size: 16px;
    padding: 10px
}

.members-back-bar a {
    color: #fff
}

.members-table-more {
    background-color: #F8F8F8;
    color: #64d4d3163;
    text-align: center;
    text-transform: lowercase
}

.members-item-arrow {
    background-image: url(/img/member-arrow.png);
    background-repeat: no-repeat;
    background-position: 95% center
}

.members-item-arrow-follows {
    background-image: url(/img/member-arrow.png);
    background-repeat: no-repeat;
    background-position: 85% center
}

.member-profile-inactive {
    background-color: #efefef;
}

.profile-container {
    margin: 0;
    padding: 0;
    background-color: #fff
}

.profile-container-background {
    background-color: #fff
}

.profile-map {
    z-index: 1;
    height: 400px
}

.profile-photo-small {
    background-color: #fff;
    padding: 20px 0
}

.profile-no-map {
    z-index: 1;
    height: 240px
}

.profile-layer {
    z-index: 10;
    position: relative;
    top: -450px;
    width: 100%
}

.profile-back-button-row {
    position: absolute;
    z-index: 99;
    width: 100%
}

.profile-back {
    background-color: #1f1f1f;
    opacity: .7;
    color: #fff;
    font-size: 16px;
    padding: 8px 10px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px
}

.profile-back a {
    color: #fff;
    padding-bottom: 2px
}

.profile-back a:hover {
    text-decoration: none;
    border-bottom: 2px solid #fff
}

.profile-content-photo {
    position: relative;
    z-index: 99;
    top: -200px;
    background-color: #fff;
    padding:5px 5px 15px;
    min-height: 200px;
}

.profile-container.no-map .profile-content-photo{
	top:50px;
	padding:0;
}

.profile-container.no-map .profile-content-photo > img{
	width:100%;
}

@media screen and (max-width: 600px) {

    .profile-content-photo {
        position: relative;
        top: 0px;
        padding: 20px 0px;
        z-index: 0;
    }
    .progile-thumbs-padding {
        padding: 1%;
        width: 33%;
        float: left;
    }
    .profile-content-text-member-public #profile-main-photo {

    }
}
.profile-content-photo-member-public {
    padding-top: 15px;
    min-height: 350px
}

.profile-photo {
    width: 100%;
    border: 1px solid #c8c8c8;
    margin-bottom: 0;
    min-width: 100%;
}

.profile-content-photo h3 {
    padding: 0;
    margin: 0 0 20px;
    font-size: 15px;
    font-weight: 600;
    color: #494949
}

.profile-video-player {
    width: 100%;
    height: 300px
}

.profile-image-thumbs {
    margin: 0
}

.progile-thumbs-padding {
    padding: 1%
}

.profile-image-thumbs img {
    margin-top: 5px;
    background-size: cover
}

.profile-image-thumbs-padding-right {
    padding-right: 5px
}

.profile-content-text {
    background-color: #fff;
    padding: 0 20px 20px
}

.profile-content-text-member-public {
    margin-top: 0
}

.profile-content-text-top {
    padding: 20px 0
}

.profile-content-text-top h1 {
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: 200
}

.profile-content-text h2 {
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: 200
}

.profile-content-text h3 {
    padding: 0;
    margin: 0 0 20px;
    font-size: 15px;
    font-weight: 600;
    color: #494949
}

.profile-content-text h4 {
    padding: 0;
    margin: 30px 0 15px;
    font-size: 15px;
    font-weight: 400;
    color: #494949
}

.profile-content-text .btn {
    margin-bottom: 5px
}

.profile-thumb-rollover {
    cursor: pointer
}

.profile-timeline-events {
    margin-bottom: 30px
}

.profile-timeline-event {
    border-bottom: 1px solid #efefef;
    margin-bottom: 5px;
    min-height: 40px
}

.profile-timeline-event .profile-timeline-event-date {
    text-align: right;
    color: #969696
}

.profile-timeline-event .profile-timeline-event-col {
    padding: 5px
}

.social-box-container {
    background-color: #EDEDED;
    padding: 10px;
    margin-bottom: 20px;
	word-wrap: break-word;
}

.social-box-container h3 {
    margin-bottom: 10px
}

.social-box-container h4 {
    margin-bottom: 0
}

.social-box-container hr {
    margin-bottom: 0;
    padding-bottom: 0
}

.social-box-container .btn {
    margin-bottom: 5px;
}

.social-box-container a.link{
	color :#336699;
}
.social-box-container a.link:hover{
	color :#24394f;
}

.social-circle-image {
    background-position: center;
    background-size: 150%
}

.social-job-box-container {
    background-color: #EDEDED;
    padding: 10px;
    margin-bottom: 20px;
	word-wrap: break-word;
}
.social-job-box-container p{
    color:#f0f0f0;
}

.social-job-box-container-yard {
    background-color: #333F48;
    color: #fff!important;
	word-wrap: break-word;
}

.social-job-box-container-professional {
    background-color: #994c3a;
    color: #fff!important;
	word-wrap: break-word;
}

.social-job-box-sub-title-yard {
    color: #fff!important;
    text-align: right;
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 5px;
    border-bottom: 1px solid #fff;
    margin-bottom: 10px
}

.social-job-box-sub-title-professional {
    color: #fff!important;
    text-align: right;
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 5px;
    border-bottom: 1px solid #fff;
    margin-bottom: 10px
}

.social-job-box-sub-title-listing-yard {
    background-color: #333F48;
    color: #fff!important;
    font-size: 13px;
    text-align: right;
    text-transform: uppercase;
    font-weight: 700;
    padding: 5px;
    margin-left: 10px
}

.social-job-box-sub-title-listing-professional {
    background-color: #994c3a;
    color: #fff!important;
    font-size: 13px;
    text-align: right;
    text-transform: uppercase;
    font-weight: 700;
    padding: 5px;
    margin-left: 10px
}

.social-job-box-container h2 {
    font-size: 20px;
    margin-bottom: 10px
}

.signup-form-container {
    margin-top: 20px;
    color: #fff
}

.signup-form-container label {
    font-weight: 400
}

.signup-form-container .form-group {
    margin-bottom: 20px
}

.signup-form-facebook {
    background-color: #3B579E;
    background-position: 10px 6px;
    background-repeat: no-repeat;
    color: #fff;
    padding: 10px;
    font-size: 18px;
    text-align: center;
}

.signup-form-facebook a {
    color: #fff
}

.signup-form-facebook a:hover {
    color: #fff;
    border-bottom: 2px solid #fff;
    text-decoration: none
}

.signup-form-tab {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 10px;
    font-size: 18px;
    text-align: center
}

.signup-form-body {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px 20px
}

.signup-member-link-text {
    color: #fff;
    font-size: 16px
}

.signup-member-link-text:hover {
    color: #fff
}

.signup-modal {
    margin: 10px;
    border: 2px solid #efefef;
    padding: 10px
}

.signup-modal-header {
    background-color: #494949;
    color: #fff
}

.advert-wizard-panel {
    margin-top: 60px;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    padding: 0 20px 20px
}

.advert-wizard-panel h2 {
    font-size: 20px;
    border-bottom: 1px solid #c8c8c8;
    padding-bottom: 10px;
    margin-bottom: 10px;
    color: #646464
}

.advert-wizard-panel hr {
    margin-bottom: 10px
}

.advert-wizard-panel legend {
    font-size: 16px;
    color: #969696
}

.advert-image {
    width: 100%;
    margin-top: 15px
}

.chart-canvas {
    width: 100%!important;
    max-width: 800px;
    height: auto!important
}

.parsley-errors-list {
    margin: 8px 0 0;
    padding: 0;
    list-style-type: none;
    font-size: .9em;
    line-height: .9em;
    opacity: 0;
    -moz-opacity: 0;
    -webkit-opacity: 0;
    color: red;
    transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    -ms-transition: all .3s ease-in;
    -moz-transition: all .3s ease-in;
    -webkit-transition: all .3s ease-in
}

.parsley-errors-list.filled {
    opacity: 1
}

.component-scroll-container {
    height: 300px;
    margin-bottom: 20px;
    overflow: scroll
}

.component-scroll-pane {
    visibility: hidden;
    position: absolute;
    top: 20px;
    width: 100%
}

.component-scroll-pane-active {
    visibility: visible
}

.component-scroll-pane ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%
}

.component-scroll-pane li {
    font-size: 18px;
    font-weight: 300;
    margin-left: 1%;
    padding: 10px 0 30px;
    width: 95%;
    height: 40px;
    border-bottom: 1px solid #efefef
}

.components-panel-format a {
    font-size: 16px
}

.components-panel-format small {
    color: #969696
}

.advertise-lead {
    margin-bottom: 40px
}

.advertise-pabel-space {
    margin-bottom: 20px
}

.advertise-option-container {
    border: 1px solid #efefef;
    border-radius: 5px;
    padding: 20px;
    background-color: #efefef
}

.advertise-option-container h2 {
    font-weight: 200;
    font-size: 24px;
    margin: 0 0 20px
}

.advertise-option-container p {
    padding: 0 0 10px
}

.advertise-option-container #price {
    font-size: 16px;
    font-weight: 500;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    padding-top: 5px;
    text-align: center
}

.advertise-option-container .price-label {
    padding: 5px 10px;
    background-color: #F7F7F7;
    float: right;
    position: relative;
    top: -4px;
    font-weight: 800;
    border-radius: 10px
}

.advertise-option-container ul {
    list-style: none;
    padding: 0;
    margin: 0
}

.advertise-option-container #form-spacer {
    height: 34px;
    margin-bottom: 15px
}

.advertise-option-highlight {
    box-shadow: 0 0 5px #ccc;
    background-color: #fff
}

.advertise-help-strip {
    margin: 20px 0;
    text-align: center;
    background-color: #F7F7F7;
    padding: 10px 5px;
    border-radius: 10px
}

.advertise-help-strip h4 {
    font-size: 24px;
    font-weight: 300
}

.advertise-circle-row {
    margin-top: 50px;
    background-color: #F7F7F7;
    text-align: center
}

.advertise-circle-row h3 {
    font-size: 18px;
    margin: 5px 0
}

.advertise-circle-row p {
    font-size: 13px;
    margin: 0 0 5px
}

.advertise-circle-row img {
    margin: auto;
    padding: 20px 0
}

.advertise-circle-row #col {
    padding: 20px
}

.advertise-circle-down-arrow {
    background-image: url(/img/advertise/down-arrow.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 80px
}

.advertise-paragraph-rows {
    margin: 40px 0;
    text-align: center
}

.advertise-paragraph-rows h3 {
    font-size: 20px;
    margin: 5px 0 20px
}

.advertise-paragraph-rows p {
    font-size: 14px;
    margin: 0 0 5px
}

.advertise-paragraph-rows #col {
    padding: 20px
}

.advertise-paragraph-rows img {
    margin: auto
}

.advertise-strap-line {
    margin: 80px 0;
    text-align: center
}

.advertise-strap-line h4 {
    font-size: 32px;
    line-height: 40px;
    padding: 0 20px;
    font-weight: 300
}

.navbar-toggle {
    padding: 15px
}

.navmenu-brand {
    text-align: right;
    margin: 0;
    padding: 20px;
    border-bottom: 1px solid #efefef
}

.nav-mobile-menu {
    font-size: 18px
}

.nav-mobile-menu li {
    text-align: left
}

.nav-mobile-menu .divider {
    padding: 0 0 10px;
    margin: 0 0 10px;
    border-bottom: 1px solid #efefef
}

.footer-slogon {
    margin-top: 50px;
    background-color: #64d4d3162;
    padding: 40px 20px;
    text-align: center;
    color: #fff
}

.footer-slogon h3 {
    font-size: 24px;
    font-weight: 300
}

.footer-slogon p {
    font-weight: 200
}

.footer-social {
    background-color: #1f1f1f;
    padding: 20px;
    text-align: center
}

.footer-social img {
    width: 52px;
    height: 52px;
    margin: 0 20px
}

.footer-links {
    padding: 20px 0 40px;
    background-color: #efefef
}

.footer-links ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.footer-links li {
    padding-bottom: 8px;
    line-height: 20px;
    font-size: 13px
}

.footer-links a {
    padding-bottom: 2px
}

.footer-links a:hover {
    text-decoration: none;
    border-bottom: 2px solid #1f1f1f
}

.footer-copyright {
    margin-top: 40px
}

@media screen and (max-width: 768px) {
	.footer-social img {
		width: 40px;
		height: 40px;
		margin: 0 10px;
	}
}

.home-place-an-advert {
    z-index: 999;
    position: absolute;
    top: 30px;
    left: 30px;
    /*width: 33%;*/
    max-width: 333px
}

.required-field-block {
    position: relative
}

.required-field-block .required-icon {
    display: inline-block;
    vertical-align: middle;
    margin: -.25em .25em 0;
    background-color: #E8E8E8;
    border-color: #E8E8E8;
    padding: .5em .8em;
    color: rgba(0, 0, 0, 0.65);
    text-transform: uppercase;
    font-weight: 400;
    border-radius: .325em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background .1s linear;
    -moz-transition: background .1s linear;
    transition: background .1s linear;
    font-size: 75%;
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    margin: 0;
    width: 30px;
    height: 30px;
    padding: 0;
    text-align: center;
    -webkit-transition: color .2s ease;
    -moz-transition: color .2s ease;
    transition: color .2s ease
}

.required-field-block .required-icon:after {
    position: absolute;
    content: "";
    right: 1px;
    top: 1px;
    z-index: -1;
    width: 0;
    height: 0;
    border-top: 0 solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 0 solid transparent;
    border-right-color: inherit;
    -webkit-transition: border-color .2s ease;
    -moz-transition: border-color .2s ease;
    transition: border-color .2s ease
}

.required-field-block .required-icon .text {
    color: #B81f1f1f0;
    font-size: 26px;
    margin: -3px 0 0 12px
}

.footer-links li.col-xs-6 {
    padding-left: 0;
    padding-right: 15px
}

.padding-top-30 {
    padding-top: 30px
}

.profile-back-button-row2 {
    width: 100%;
    margin-right: 0;
    margin-left: 0
}

.btn-professional {
    white-space: normal
}

.members-panel img {
    max-width: 53px
}

.label-danger {
    background-color: #994c3a;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover
{
    background-color: #767c83;
    border-color: #767c83;
}

.pagination>li>a, .pagination>li>span {
    color: #767c83;
}

.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover
{
    background-color: #efefef;
    border-color: #efefef;
    color: #767c83;
}

.btn.focus, .btn:focus, .btn:hover
{
    color: #efefef;
}

.btn-primary {
    color: #fff;
    background-color: #333F48;
    border-color: #333F48;
}

.btn-join-new {
    background-color: #333F48;
    border-color: #333F48;

    color: #fff;
}

.btn-primary:hover {
    color: #efefef;
    background-color: #767c83;
    border-color: #767c83;
}
.btn-default:hover {
    background-color: #494949;
    border-color: #494949;
}

.alert-info {
    color: #efefef;
    background-color: #767c83;
    border-color: #767c83;
}
.btn-warning {
    color: #fff;
    background-color: #988364;
    border-color: #988364;
}
.btn-warning:hover {
    color: #fff;
    background-color: #a67d45;
    border-color: #a67d45;
}



.btn-info {
    color: #fff;
    background-color: #333F48;
    border-color: #333F48;
}

.btn-info:hover {
    color: #fff;
    background-color: #767c83;
    border-color: #767c83;
}

.label-default {
    background-color: #494949;
}

.alert-info hr {
    border-top-color: #494949;
}

.stripe-button-el,
.stripe-button-el span {
    color: #fff;
    background: #988364 !important;
    border-color: #988364 !important;
}

.mainmenu .submenu:before, .mainmenu .submenu:after,
.mainmenu .submenu .promo:before {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.advertise-option-container {
    position: relative;
}

.advertise-option-container .bottom {
    position: absolute;
    bottom: 20px;
    padding-right: 40px;
    width: 100%;
}

.main-advert {
    margin-top: 5px;
}



.mb0{margin-bottom:0 !important;}
.mb5{margin-bottom:5px !important;}
.mb10{margin-bottom:10px !important;}
.mb15{margin-bottom:15px !important;}
.mb20{margin-bottom:20px !important;}
.mt0{margin-top:0px !important;}
.mt10{margin-top:10px !important;}
.mt15{margin-top:15px !important;}
.mt20{margin-top:20px !important;}

.p5{padding:5px;}
.p10{padding:10px;}
.p15{padding:15px;}

div.input-label {
    position: relative;
}
div.input-label label {
    position: absolute;
    top: 8px;
    left: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #336699;
    padding: 0 !important;
    z-index: 99;
}
div.input-label i {
    position: absolute;
    font-size: 20px;
    color: #999;
    top: 8px;
    right: 10px;
    z-index: 98;
}
div.input-label input, div.input-label select {
    padding: 26px 10px 5px;
    height: 60px;
    border-color: #999;
	border-radius:2px;
}
div.input-label input:focus, div.input-label select:focus{
	border-color: #999;box-shadow:0 0 1px #222;position:relative;z-index:98;
}

table.pricing{color:#888;margin:-5px 0 15px;border:0;}
table.pricing td{border:0;border-bottom:1px solid #ddd;padding:5px 5px 5px 0;vertical-align:top;}
table.pricing tr:last-child td{border-bottom:0;}

.btn{font-weight:200 !important;border-radius:2px !important;}

.mr5 { margin-right: 5px;}
.mr10 { margin-right: 10px;}
.mr15 { margin-right: 15px;}
.mr20 { margin-right: 20px;}

.ml5 { margin-left: 5px;}
.ml10 { margin-left: 10px;}
.ml15 { margin-left: 15px;}
.ml20 { margin-left: 20px;}

.p15{padding:15px;}

.stripe-button-el{margin-top:10px;}

.advert-wizard{border-left:2px solid #ccc;margin-top:30px;margin-bottom:40px;}
.advert-wizard .advert-wizard-section{border-left:2px solid #ccc;padding:0 0 15px 25px;margin:15px 0 0;position:relative;left:-2px;}
.advert-wizard .advert-wizard-section:first-child{margin-top:0;}
.advert-wizard .advert-wizard-section:last-child{border-left:2px solid #fff;}
.advert-wizard-title{position:relative;margin:0 0 15px;}
.advert-wizard-label{cursor:pointer;position:absolute;top:-4px;color:#666;}
.advert-wizard-label span{font-family:'Work Sans',sans-serif;font-size:11px;}
.advert-wizard-title:hover .advert-wizard-label{color:#000;}
.advert-wizard-step{position:absolute;top:0;left:-31px;height:10px;width:10px;border-radius:50%;background:#ccc;text-align:center;color:transparent;text-indent:-1000em;font-size:14px;line-height:22px;}
.advert-wizard-title:hover .advert-wizard-step{background:#aaa;}
.advert-wizard-content{padding:30px 0 0;display:none;}

.advert-wizard .advert-wizard-section.complete .advert-wizard-step{left:-36px;top:-5px;width:20px;height:20px;background:#199906;color:#fff;text-indent:0;}
.advert-wizard .advert-wizard-section.complete .advert-wizard-label span{display:none;}

.advert-wizard .advert-wizard-section.active .advert-wizard-step{left:-37px;top:-6px;width:22px;height:22px;border:2px solid #aaa;background:#fff;}
.advert-wizard .advert-wizard-section.active .advert-wizard-label{color:#222;}
.advert-wizard .advert-wizard-section.active .advert-wizard-content{display:block;}

.advert-wizard .advert-wizard-section.complete.active .advert-wizard-step{color:#199906;}

.fn-media-manager,.video-wrapper{position:relative;padding:50% 0;background:#aaa;display:block;overflow:hidden;}
.fn-media-manager img,.video-wrapper img{position:absolute;top:0;left:0;width:100%;}
.fileinput-button input{z-index:4;}
.fileinput-action{display:block;position:absolute;z-index:3;top:0;left:0;width:100%;padding-top:30%;font-size:30px;text-align:center;color:#fff;}
.fileinput-action i{text-shadow:0 0 5px #000;opacity:0.6;}
.fileinput-button:hover .fileinput-action i,.fn-media-manager:hover .fileinput-action i,.video-wrapper:hover .fileinput-action i{
text-shadow:0 0 15px #888;opacity:1;}
.fileinput-button img{z-index:2;}

#advert-subscription{margin:0 0 15px;background:#f0f0f0;}
#advert-subscription #subscription-header{background:#122b40;padding:10px 15px;color:#fff;margin:0;}
#advert-subscription .members-icon-large{font-family:'Work Sans',sans-serif;margin:0 0 10px;font-size:14px;}
#advert-subscription a{color:#336699;}
#advert-subscription .btn.btn-primary,#advert-subscription .btn.btn-warning,#advert-subscription .btn.btn-info{color:#fff;}

#manage-media img.fn-media-manager-picture{max-width:100%;} 

.fn-competition-section-wrap{padding-left:15px;padding-top:15px;}
.fn-competition-section{padding-top:15px;background:#f6f6f6;margin-bottom:15px;}
.fn-competition-section h5{font-size:16px;margin:0 0 10px;text-transform:uppercase;}

.fn-horse-profile-show-phone-number{color:#336699;cursor:pointer;}
.fn-horse-profile-show-phone-number:hover{text-decoration:underline;}
.similar-horses{padding:25px 0;background:#242424;border-top:3px solid #988364;}
.similar-horses a{color:#e4e4e4;}
.similar-horses a:hover,.similar-horse:hover a{color:#333;}
.similar-horses h3{color:#988364;padding:0;line-height:100%;}
.similar-horses .btn-horse{margin-top:10px;padding:37% 10px;}
.similar-horse:hover{margin:-5px;padding:5px;background:#f4f4f4;}

.nav-tabs li a:focus{outline:none;}

@media screen and (min-width: 768px) {
	#subscription-options .affix{top:0;margin:-15px;padding:15px 15px 0;z-index:99;background:rgba(255, 255, 255, 0.5);}
}
@media screen and (max-width: 768px) {
	.affix{position:static !important;}
}
